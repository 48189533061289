<template>
  <v-dialog
    v-model="dialog"
    max-width="650"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card v-if="loading" min-width="320">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="grey"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="headline">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-chip v-if="isSelf" color="success" label>ログインユーザー</v-chip>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row v-show="!isCreate">
              <v-col>
                <v-text-field
                  v-model="item.id"
                  label="ID"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.username"
                  label="ユーザー名"
                  :rules="rules.username"
                  required
                  :readonly="!isCreate"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="item.group"
                  label="グループ"
                  :items="groupNames"
                  item-text="name"
                  item-value="id"
                  :rules="rules.group"
                  required
                  :readonly="isSelf"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.email"
                  label="メールアドレス"
                  :rules="rules.email"
                  :readonly="!isCreate"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="!isCreate">
              <v-col>
                管理ユーザーを削除する場合は、以下を無効にすると一覧画面に削除ボタンが表示されます。
                <v-switch
                  v-model="item.enabled"
                  label="無効 / 有効"
                  :readonly="isSelf"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="!isCreate">
              <v-col>
                <v-text-field
                  v-model="item.status"
                  label="アカウントのステータス"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5 class="subtitle-2 mb-1">
                  自己診断機能のメール通知設定
                </h5>
                <v-switch
                  v-model="item.is_sns_topic_subscribe"
                  label="無効 / 有効"
                ></v-switch>
                <div v-if="!isCreate && item.is_sns_topic_subscribe">
                  <v-icon
                    :color="
                      item.is_sns_subscription_confirmed ? 'success' : 'warning'
                    "
                    small
                    >{{
                      item.is_sns_subscription_confirmed
                        ? 'mdi-check-circle'
                        : 'mdi-alert'
                    }}</v-icon
                  ><span
                    class="text-caption"
                    :class="
                      item.is_sns_subscription_confirmed
                        ? 'success--text'
                        : 'warning--text'
                    "
                    >{{
                      item.is_sns_subscription_confirmed
                        ? 'メール確認済み'
                        : 'メール未確認'
                    }}</span
                  >
                </div>
                <p class="caption text--secondary">
                  ※自己診断機能のメール通知設定を有効にして保存すると、上記で入力いただいたメールアドレス宛てにKAMEKERアプリ招待メールと同時に、
                  「AWS
                  Notification」から自己診断メール通知を認証するための英文のメールが送信されます。<br />
                  受信したメール本文内のリンク「Confirm
                  subscription」をクリックすることで認証が完了し、自己診断異常時のメール通知を受けることができます。<br />
                  （こちらの認証がされ
                  ていないと自己診断異常時のメール通知を受けることができませんのでご注意ください。）<br />
                  ※無効にすると自己診断異常時のメール通知が受信できません。<br />
                  ※一度無効にし、有効に戻すと再度認証メールが送信され、認証を行っていただく必要があります。
                </p>
              </v-col>
            </v-row>
            <v-row v-if="!isCreate && item.is_sns_topic_subscribe">
              <v-col>
                <v-text-field
                  v-model="item.sns_subscription_arn"
                  label="Amazon SNS Subscription Arn"
                  readonly
                ></v-text-field>
                <p class="caption text--secondary">
                  自己診断機能のメール通知に必要なユーザごとのユニークな値。
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h5 class="subtitle-2 mb-1">
                  浸水アラートのメール通知設定
                </h5>
                <v-switch
                  v-model="item.is_subscribed_for_device_alert_email"
                  label="無効 / 有効"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            キャンセル
          </v-btn>
          <v-btn depressed dark :color="mainColor" @click="save">保存</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getAdminUser, postAdminUser, putAdminUser } from '@/api'
import { MAIN_COLOR, VALIDATION_MAIL_REGEXP } from '@/constants'

export default {
  name: 'AdminUserEditor',
  props: {
    dialog: Boolean,
    adminUserId: String
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      valid: true,
      rules: {
        username: [
          v => !!v || 'ユーザー名は必須です',
          v =>
            /[ 　]+/.test(v) ? '半角・全角スペースは含めないでください。' : true
        ],
        group: [v => !!v || 'グループは必須です'],
        email: [
          v => !!v || 'メールアドレスは必須です。',
          v => VALIDATION_MAIL_REGEXP.test(v) || '不正なフォーマットです。'
        ]
      },
      defaultItem: {
        id: '',
        username: '',
        name: '',
        group: '',
        email: '',
        enabled: false,
        status: '',
        sns_subscription_arn: '',
        is_sns_topic_subscribe: true,
        is_sns_subscription_confirmed: false,
        is_subscribed_for_device_alert_email: true
      },
      editItem: {
        id: '',
        username: '',
        name: '',
        group: '',
        email: '',
        enabled: false,
        status: '',
        sns_subscription_arn: '',
        is_sns_topic_subscribe: true,
        is_sns_subscription_confirmed: false,
        is_subscribed_for_device_alert_email: true
      }
    }
  },
  computed: {
    ...mapGetters(['account', 'groupNames']),
    isCreate() {
      return this.adminUserId === ''
    },
    isSelf() {
      return this.adminUserId === this.account.id
    },
    title() {
      return this.isCreate ? '新規登録' : '編集'
    },
    item() {
      return this.editItem
    },
    showSNSSubscriptionArn() {
      return this.editItem.sns_subscription_arn != ''
    }
  },
  watch: {
    async dialog(openend) {
      if (openend && !this.isCreate) {
        await this.setItems()
      }
    }
  },
  methods: {
    ...mapActions(['addAdminUser', 'updateAdminUser', 'setFullscreenLoading']),
    close() {
      this.$refs.form.resetValidation()
      this.editItem = Object.assign(this.editItem, this.defaultItem)
      this.$emit('close')
    },
    async setItems() {
      const adminUser = await this.adminUserById(this.adminUserId)
      this.editItem = Object.assign(this.editItem, adminUser)
      this.editItem.is_sns_topic_subscribe =
        this.editItem.sns_subscription_arn !== ''
    },
    async adminUserById(id) {
      this.loading = true
      let adminUser
      try {
        const res = await getAdminUser(id)
        adminUser = res.admin_user
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.loading = false
      return adminUser
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setFullscreenLoading(true)
      let res
      try {
        if (this.isCreate) {
          res = await postAdminUser(this.item)
          this.addAdminUser(res.admin_user)
        } else {
          res = await putAdminUser(this.item)
          this.updateAdminUser(res.admin_user)
        }
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.close()
      this.setFullscreenLoading(false)
    }
  }
}
</script>
