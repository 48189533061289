<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <h1>管理ユーザー</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right">
          <v-btn :color="mainColor" dark @click.stop="doCreate">
            新規登録
          </v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer v-if="isAdminGroup"></v-spacer>
          <v-select
            v-if="isAdminGroup"
            v-model="searchByGroup"
            label="グループで絞り込み"
            :items="groupNames"
            item-text="name"
            item-value="id"
            style="top: 10px"
            clearable
          ></v-select>
          <v-spacer />
          <v-btn icon @click="doLoad(false)" class="ml-2">
            <v-progress-circular
              v-show="loading"
              :indeterminate="loading"
            ></v-progress-circular>
            <v-icon v-show="!loading">mdi-reload</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title>
          <v-spacer />
          <v-btn @click.stop="downloadCsv" class="mr-2" plain>
            全管理ユーザーの<br />CSVダウンロード
            <v-icon small class="ml-2">
              mdi-download
            </v-icon>
          </v-btn>
          <v-btn @click.stop="openLoginHistoriesDialog" class="mr-2" plain>
            全管理ユーザーの<br />ログイン履歴
            <v-icon small class="ml-2">
              mdi-history
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
        >
          <template v-slot:[`item.group`]="{ item }">
            {{ displayGroupName(item.group) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="item.enabled ? 'success' : 'grey'"
              label
              small
              outlined
              >{{ item.enabled ? `有効` : '無効' }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon class="mr-2" small @click="doEdit(item.id)">
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="!isSelf(item.id) && !item.enabled"
              color="red"
              small
              @click="doDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            現在登録された管理ユーザーはありません。
          </template>
        </v-data-table>
      </v-card>
      <admin-user-editor
        :dialog="dialogAdminUserEditor"
        :adminUserId="editAdminUserId"
        @close="close"
      ></admin-user-editor>
      <login-histories-dialog :dialog="dialogLoginHistories" @close="close" />
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  getAdminUsers,
  deleteAdminUser,
  getGroups,
  getAdminUsersCsvData
} from '@/api'
import { downloadCSVByResponse } from '@/middleware/api'
import AdminUserEditor from '@/components/admin-user-editor'
import LoginHistoriesDialog from '@/components/login-histories-dialog.vue'
import { MAIN_COLOR } from '@/constants'

export default {
  name: 'AdminUserIndex',
  components: {
    AdminUserEditor,
    LoginHistoriesDialog
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      search: '',
      searchByGroup: '',
      dialogAdminUserEditor: false,
      dialogLoginHistories: false,
      headers: [
        { text: 'ユーザー名', value: 'username', sortable: true },
        { text: 'グループ', value: 'group', filterable: false, sortable: true },
        {
          text: '有効 / 無効 ',
          value: 'status',
          filterable: false,
          sortable: true
        },
        {
          text: '編集 / 削除',
          value: 'action',
          filterable: false,
          sortable: false
        }
      ],
      editAdminUserId: ''
    }
  },
  computed: {
    ...mapGetters(['account', 'adminUsers', 'groupNames', 'isAdminGroup']),
    items() {
      if (!this.searchByGroup) {
        return this.adminUsers
      }
      return this.adminUsers.filter(u => u.group === this.searchByGroup)
    }
  },
  async created() {
    this.doLoad(true)
  },
  methods: {
    ...mapActions([
      'setAdminUsers',
      'updateAdminUser',
      'deleteAdminUser',
      'setGroups',
      'setFullscreenLoading'
    ]),
    async doLoad(storeCheck) {
      if (this.loading) {
        return
      }
      this.loading = true
      await this.initAdminUsers(storeCheck)
      await this.initGroup(storeCheck)
      this.loading = false
    },
    async initAdminUsers(storeCheck) {
      if (storeCheck && this.$store.getters.adminUsers.length > 0) {
        return
      }
      let res
      try {
        res = await getAdminUsers()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setAdminUsers(res.admin_users)
    },
    async initGroup(storeCheck) {
      if (storeCheck && this.$store.getters.groups.length > 0) {
        return
      }
      let res
      try {
        res = await getGroups()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setGroups(res.groups)
    },
    doCreate() {
      this.editAdminUserId = ''
      this.dialogAdminUserEditor = true
    },
    doEdit(adminUserId) {
      this.editAdminUserId = adminUserId
      this.dialogAdminUserEditor = true
    },
    async doDelete(adminUser) {
      const message = adminUser.enabled
        ? `ユーザー名: ${adminUser.username} を無効にしてもよろしいですか？\n削除は無効にしたあとに行うことができます。`
        : `ユーザー名: ${adminUser.username} を削除してもよろしいですか？`
      if (!confirm(message)) {
        return
      }

      this.setFullscreenLoading(true)
      try {
        await deleteAdminUser(adminUser.id)
        this.deleteAdminUser(adminUser)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.setFullscreenLoading(false)
    },
    close() {
      this.dialogAdminUserEditor = false
      this.dialogLoginHistories = false
    },
    isSelf(id) {
      return this.account.id === id
    },
    displayGroupName(id) {
      const group = this.groupNames.find(g => g.id == id)
      if (!group) {
        return ''
      }
      return group.name
    },
    async downloadCsv() {
      this.setFullscreenLoading(true)
      let res = null
      try {
        res = await getAdminUsersCsvData()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      } finally {
        this.setFullscreenLoading(false)
      }
      if (res === null) {
        return
      }
      // csvダウンロード処理
      downloadCSVByResponse(res)
    },
    openLoginHistoriesDialog() {
      this.dialogLoginHistories = true
    }
  }
}
</script>
