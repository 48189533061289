<template>
  <v-dialog v-model="dialog" max-width="920" @click:outside="close">
    <v-card>
      <v-card-title>
        <span class="headline">全管理ユーザーのログイン履歴</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <p>
            全管理ユーザーの最長90日分のログイン履歴を表示します。
          </p>
          <v-btn
            :color="mainColor"
            dark
            @click.stop="downloadLoginHistoriesCsv"
          >
            CSVダウンロード
          </v-btn>
        </div>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="elevation-0"
                :headers="header"
                :items="loginHistories"
                :loading="loading"
              >
                <template v-slot:[`item.logged_at`]="{ item }">
                  {{ item.logged_at | moment }}
                </template>
                <template v-slot:[`item.group`]="{ item }">
                  {{ item.group }}
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  {{ item.status }}
                </template>
                <template v-slot:[`item.error_message`]="{ item }">
                  {{ item.error_message }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { getLoginHistories, getLoginHistoriesCsvData } from '@/api'
import { downloadCSVByResponse } from '@/middleware/api'
import { MAIN_COLOR } from '@/constants'
import moment from 'moment'

export default {
  name: 'LoginHistoriesDialog',
  props: {
    dialog: Boolean
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      loginHistories: [],
      header: [
        {
          text: '時刻',
          align: 'start',
          value: 'logged_at',
          sortable: true
        },
        {
          text: 'グループ',
          align: 'start',
          value: 'group',
          sortable: false
        },
        {
          text: '名前',
          align: 'start',
          value: 'name',
          sortable: false
        },
        {
          text: 'ステータス',
          align: 'start',
          value: 'status',
          sortable: false
        },
        {
          text: 'エラーメッセージ',
          align: 'start',
          value: 'error_message',
          sortable: false,
          width: 250
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setFullscreenLoading']),
    async initLoginHistories() {
      let res
      try {
        res = await getLoginHistories()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      // 時刻の降順で一覧表示させる
      this.loginHistories = res.login_histories.reverse()
    },
    close() {
      this.loginHistories = []
      this.$emit('close')
    },
    async downloadLoginHistoriesCsv() {
      this.setFullscreenLoading(true)
      let res = null
      try {
        res = await getLoginHistoriesCsvData()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      } finally {
        this.setFullscreenLoading(false)
      }

      if (res === null) {
        return
      }
      // csvダウンロード処理
      downloadCSVByResponse(res)
    }
  },
  watch: {
    async dialog() {
      if (!this.dialog) {
        return
      }
      this.loading = true
      await this.initLoginHistories()
      this.loading = false
    }
  },
  filters: {
    moment(timestamp) {
      return moment(timestamp).format('YYYY/MM/DD HH:mm:ss')
    }
  }
}
</script>
