var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"920"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("全管理ユーザーのログイン履歴")])]),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',[_vm._v(" 全管理ユーザーの最長90日分のログイン履歴を表示します。 ")]),_c('v-btn',{attrs:{"color":_vm.mainColor,"dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadLoginHistoriesCsv.apply(null, arguments)}}},[_vm._v(" CSVダウンロード ")])],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header,"items":_vm.loginHistories,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.logged_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.logged_at))+" ")]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.group)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.error_message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.error_message)+" ")]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" 閉じる ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }